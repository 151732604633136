import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import axios from 'axios';
// import { NotificationContainer, NotificationManager, } from "react-notifications";
import DashboardHeader from "./DashboardHeader";

const FundingOptions = () => {
    const [content, setContent] = useState();

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const auth = JSON.parse(localStorage.getItem("auth_user_info"));
    const applicantID = auth ? auth.id : null;


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/applicant/get-application`, {
            headers: {
                "ApplicantId": applicantID,
                "Event": "funding",
                "SessionKey": "123"
            }
        })
            .then(response => {
                if (response.data.data) {
                    setContent(response.data.data.content);
                }
                setLoading(false);

            })
            .catch(error => console.error(`Error: ${error}`));
    }, []);

    const handleContentChange = () => {
        // const content = event.target.value;

        setContent(prevChecked => prevChecked === 0 ? 1 : 0);

    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // if (content === 0) {
        //     NotificationManager.error("Content field is required");
        //     return;
        // }

        axios.post(`${process.env.REACT_APP_BASE_URL}/applicant/funding`, {
            "applicantId": applicantID,
            "requestContent": content
        }, {
            headers: {
                "SessionKey": "123",
                "Content-Type": "application/json"
            }
        })
            .then(response => navigate("/application/privacy-policy-statement"))
            .catch(error => console.error(`Error: ${error}`));
    };

    return (
        <div>
            {/* Header Start */}
            <div className="mb-5" >

                <DashboardHeader />
            </div>

            <div className="container row justify-content-center m-auto mb-5">
                <div className="col-md-10">
                    <div className="row justify-content-center">
                        <div className="col-sm-12 col-md-4">
                            <Navbar />
                        </div>
                        <div className="col-sm-12 col-md-8 mb-4">
                            {loading ? (
                                <div className="d-flex justify-content-center">
                                    <div className="spinner-bubble spinner-bubble-primary m-5"></div>
                                </div>

                            ) : (
                                <form onSubmit={handleSubmit}>
                                    <div className="card ">
                                        <div className="card-body">
                                            <h4>Funding Options</h4>
                                            <p className="w-100">
                                                Please note that this training program covers the costs of accommodation and food during the two-week training in Islamabad. However, participants are responsible for making their own travel arrangements to and from the training location.
                                            </p>
                                            <p className="w-100">
                                                For those seeking financial aid to cover travel expenses, a limited number of scholarships are available to eligible applicants. Scholarship recipients will be chosen based on a competitive selection process that considers both financial need and merit.
                                            </p>
                                            <div className="ul-widget-app__task-list my-4">
                                                <label className="checkbox checkbox-primary">
                                                    <input type="checkbox" checked={content === 1} onChange={handleContentChange} />
                                                    <strong>
                                                        Select the checkbox below if you wish to apply for this scholarship. Applying for the scholarship will not affect your selection process.
                                                    </strong>
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <div className="mc-footer">
                                                <div className="row text-center">
                                                    <div className="col-lg-12 ">

                                                        <button type="submit" className="btn btn-primary text-white m-1">
                                                            Save & Next
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-secondary m-1"
                                                            onClick={() => navigate('/application/privacy-policy-statement')}
                                                        >
                                                            Skip
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {/* <NotificationContainer /> */}
        </div>
    );
};

export default FundingOptions;
