import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import DashboardHeader from './DashboardHeader';
import { NotificationContainer, NotificationManager, } from "react-notifications";

const ReferenceLetter = () => {
    const [file, setFile] = useState(null);
    const navigate = useNavigate();
    const [inputKey, setInputKey] = useState(Date.now());
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(true);
    const auth = JSON.parse(localStorage.getItem("auth_user_info"));
    const applicantID = auth ? auth.id : null;

    // useEffect(() => {
    //     console.log(file);
    // }, [file]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BASE_URL}/applicant/get-application`, {
            method: 'GET',
            headers: {
                'ApplicantId': applicantID,
                'Event': 'reference',
                'SessionKey': '123'
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data.data) {
                    setFormData(data.data)
                }
            })
            .then(() => setLoading(false))
            .catch(error => console.error(error));
    }, []);

    const handleFileChange = (e) => {
        // console.log("I am working")
        if (e.target.files.length > 0) {
            if (e.target.files[0].size <= 5000000 && e.target.files[0].type === 'application/pdf') {
                setFile(e.target.files[0]);
            } else {
                NotificationManager.error("File must be a PDF and less than 5MB");
                setInputKey(Date.now()); // Reset the file input field
            }
        } else {
            NotificationManager.error('No file selected');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate form fields
        const name = e.target.elements.name.value;
        const organization = e.target.elements.organization.value;
        const designation = e.target.elements.designation.value;
        const contact = e.target.elements.contact.value;
        const email = e.target.elements.email.value;
        // const fileCheck = e.target.elements.file.value;
        // console.log(formData.file);
        // Check for every value
        if (!name) {
            NotificationManager.error("Name field is required");
            return;
        }
        if (!organization) {
            NotificationManager.error("Organization field is required");
            return;
        }
        if (!designation) {
            NotificationManager.error("Designation field is required");
            return;
        }
        if (!contact) {
            NotificationManager.error("Contact field is required");
            return;
        }
        if (!email) {
            NotificationManager.error("Email field is required");
            return;
        }
        if (!file && !formData?.file) {
            NotificationManager.error("Reference letter is required");
            return;
        }

        if (file) {
            // Upload file API
            const subFormData = new FormData();
            subFormData.append('file', file);

            await fetch(`${process.env.REACT_APP_BASE_URL}/util/upload`, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'ApplicantId': applicantID,
                    'Event': 'letter',
                    'SessionKey': '123',
                },
                body: subFormData
            });
        };



        // Submit form Submit other fields API
        await fetch(`${process.env.REACT_APP_BASE_URL}/applicant/referece-letter`, {
            method: 'POST',
            headers: {
                'SessionKey': '123',
                'Content-Type': 'application/json'

            },
            body: JSON.stringify({
                applicantId: applicantID,
                name,
                organization,
                designation,
                contact,
                email
            })
        });

        // Navigate to next page
        navigate('/application/funding-options');
    };

    return (
        <div>
            {/* Header Start */}
            <div className="mb-5">
                <DashboardHeader />
            </div>

            <div className="container row justify-content-center m-auto mb-5">
                <div className="col-md-10">
                    <div className="row justify-content-center">
                        <div className="col-sm-12 col-md-4">
                            <Navbar />
                        </div>

                        <div className="col-sm-12 col-md-8 mb-4">
                            {loading ? (
                                <div className="d-flex justify-content-center">
                                    <div className="spinner-bubble spinner-bubble-primary m-5"></div>
                                </div>

                            ) : (
                                <form onSubmit={handleSubmit}>
                                    <div className="card">
                                        <div className="card-body">
                                            <h4>Reference Letter</h4>
                                            <div className="row">
                                                <div className="form-group mb-3 col-12">
                                                    <h6 className='m-0'>{formData.refFrom}</h6>

                                                </div>
                                                <div className="form-group mb-3 col-12">
                                                    <label htmlFor="name">Name  <span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control" id="name" placeholder="Enter Name" defaultValue={formData.name} />
                                                </div>
                                                <div className="form-group mb-3 col-12">
                                                    <label htmlFor="organization">Organization  <span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control" id="organization" placeholder="Enter Organization" defaultValue={formData.organization} />
                                                </div>
                                                <div className="form-group mb-3 col-12">
                                                    <label htmlFor="designation">Designation  <span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control" id="designation" placeholder="Enter Designation" defaultValue={formData.designation} />
                                                </div>
                                                <div className="form-group mb-3 col-12">
                                                    <label htmlFor="contact">Contact Number  <span className="text-danger">*</span></label>
                                                    <input type="number" className="form-control" id="contact" placeholder="Enter Contact Number" defaultValue={formData.contact} />
                                                </div>
                                                <div className="form-group mb-3 col-12">
                                                    <label htmlFor="email">Email  <span className="text-danger">*</span></label>
                                                    <input type="email" className="form-control" id="email" placeholder="Enter Email" defaultValue={formData.email} />
                                                </div>
                                                <label htmlFor="file">Upload Reference Letter  <span className="text-danger">*</span></label>
                                                <div className="input-group mb-3 col-12">
                                                    <input type="file" className="form-control" id="file" onChange={handleFileChange} key={inputKey} accept=".pdf" />
                                                    <label className="input-group-text" htmlFor="file">Upload</label>
                                                </div>
                                                <div className="input-group mb-3 col-12">
                                                    {formData.file && <button type="button" className="btn btn-outline-primary m-1" onClick={() => window.open(formData.file, '_blank')}>View</button>}

                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <div className="mc-footer">
                                                <div className="row text-center">
                                                    <div className="col-lg-12">
                                                        <button type="submit" className="btn btn-primary text-white m-1">Save & Next</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            )}
                        </div>

                    </div>
                </div>
            </div>
            <NotificationContainer />
        </div>

    )

};

export default ReferenceLetter;
