import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { Parser } from 'json2csv';
import FileSaver from 'file-saver';
import axios from 'axios';

const DashboardTable = ({ event, title, type }) => {
    const navigate = useNavigate();
    const [applications, setApplications] = useState([]);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(true);

    let apiCallURL = null;

    if (type === "report") {
        apiCallURL = `${process.env.REACT_APP_BASE_URL}/applicant/report`
    } else {
        apiCallURL = `${process.env.REACT_APP_BASE_URL}/applicant/get-application-list`
    }

    // Fetch Applications information
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    apiCallURL,
                    {
                        headers: {
                            accept: "*/*",
                            Event: event,
                            SessionKey: "123",
                        },
                    }
                );
                const data = await response.json();
                setApplications(data.data);
                setMessage(data.message);
                // console.log(data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching personal information data:", error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    // // Fetch Applications information and download as Excel
    // const fetchDataAndDownload = async () => {
    //     try {
    //         const response = await axios.get(
    //             `${process.env.REACT_APP_BASE_URL}/applicant/get-application-list`,
    //             {
    //                 headers: {
    //                     accept: "*/*",
    //                     Event: event,
    //                     SessionKey: "123",
    //                 },
    //                 responseType: 'blob', // Tell axios to get the response as a Blob
    //             }
    //         );
    //         const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    //         FileSaver.saveAs(blob, 'data.xlsx');
    //         setLoading(false);

    //     } catch (error) {
    //         console.error("Error fetching personal information data:", error);
    //         setLoading(false);
    //     }
    // };

    // Fetch Applications information and download as CSV


    // Function to convert JSON data to CSV
    const jsonToCsv = (data) => {
        const csvRows = [];
        const headers = Object.keys(data[0]);
        csvRows.push(headers.join(','));

        for (const row of data) {
            const values = headers.map(header => {
                const escaped = ('' + row[header]).replace(/"/g, '\\"');
                return `"${escaped}"`;
            });
            csvRows.push(values.join(','));
        }

        return csvRows.join('\n');
    };

    // Fetch Applications information and download as CSV
    const fetchDataAndDownload = async () => {
        if (type === "report") {
            // Convert JSON data to CSV
            const csvData = jsonToCsv(applications);
            // console.log(csvData);

            const blob = new Blob([csvData], { type: 'text/csv;charset=UTF-8' });

            // Use FileSaver to save the Blob as a CSV file
            FileSaver.saveAs(blob, `${title}.csv`);

            setLoading(false);
        } else {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BASE_URL}/applicant/get-application-export-list`,
                    {
                        headers: {
                            accept: "*/*",
                            Event: event,
                            SessionKey: "123",
                        }
                    }
                );
                const data = response.data.data; // Extract the data array from the response

                // Check if data is null or undefined
                if (!data) {
                    console.log('No data received');
                    return;
                }


                // Convert JSON data to CSV
                const csvData = jsonToCsv(data);
                // console.log(csvData);

                // Create a Blob from the CSV data
                const blob = new Blob([csvData], { type: 'text/csv;charset=UTF-8' });

                // Use FileSaver to save the Blob as a CSV file
                FileSaver.saveAs(blob, 'data.csv');

                setLoading(false);

            } catch (error) {
                console.error("Error fetching personal information data:", error);
                setLoading(false);
            }
        }


    };

    // const allKeys = ['id', 'name', 'address', 'education', 'experience', 'nationlity', 'gender', 'email', 'contact', 'status'];
    const allKeys = ['id', 'name', 'email'];


    return (
        <div>
            {loading ? (
                <div className="d-flex justify-content-center">
                    <div className="spinner-bubble spinner-bubble-primary m-5"></div>
                </div>
            ) : (<div className="card">
                <div className="card-body">
                    <div className="simple-card-title d-flex justify-content-between align-items-center">
                        <h3 className="text-capitalize mb-1">{title}</h3>
                        <button className="btn-icon m-1 text-capitalize btn btn-primary text-white" onClick={fetchDataAndDownload}>
                            <span className="ul-btn__icon">
                                <i className="i-File-Excel"></i>
                            </span>
                            <span className="ul-btn__text">Import Data</span>
                        </button>
                    </div>




                    {/* {applications && (
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    {allKeys.map((key) => (
                                        <th key={key}>{key.charAt(0).toUpperCase() + key.slice(1)}</th>
                                    ))}
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {applications.map((application) => (
                                    <tr key={application.id}>
                                        {allKeys.map((key) => (
                                            <td key={key}>{application[key] || 'N/A'}</td>
                                        ))}
                                        <td>
                                            <button
                                                type="button"
                                                className="btn-icon m-1 text-capitalize btn btn-outline-primary"
                                                onClick={() =>
                                                    navigate(
                                                        `/applications/view-application/${application.id}`
                                                    )
                                                }
                                            >
                                                <span className="ul-btn__icon">
                                                    <i className="i-Information"></i>
                                                </span>
                                                View
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )} */}




                    {/* {applications && applications.length > 0 && (
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    {Object.keys(applications[0]).map((key) => (
                                        <th key={key}>{key.charAt(0).toUpperCase() + key.slice(1)}</th>
                                    ))}
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {applications.map((application) => (
                                    <tr key={application.id}>
                                        {Object.keys(application).map((key) => (
                                            <td key={key}>{application[key] || 'N/A'}</td>
                                        ))}
                                        <td>
                                            <button
                                                type="button"
                                                className="btn-icon m-1 text-capitalize btn btn-outline-primary"
                                                onClick={() =>
                                                    navigate(
                                                        `/applications/view-application/${application.id}`
                                                    )
                                                }
                                            >
                                                <span className="ul-btn__icon">
                                                    <i className="i-Information"></i>
                                                </span>
                                                View
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )} */}
                    {applications && applications.length > 0 && (
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    {['id', 'name', 'email'].concat(
                                        Object.keys(applications[0]).filter(
                                            (key) => !['id', 'name', 'email'].includes(key)
                                        )
                                    ).map((key) => (
                                        <th key={key}>{key.charAt(0).toUpperCase() + key.slice(1)}</th>
                                    ))}
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {applications.map((application) => (
                                    <tr key={application.id}>
                                        {['id', 'name', 'email'].concat(
                                            Object.keys(application).filter(
                                                (key) => !['id', 'name', 'email'].includes(key)
                                            )
                                        ).map((key) => (
                                            <td key={key}>{application[key] || 'N/A'}</td>
                                        ))}
                                        <td>
                                            <button
                                                type="button"
                                                className="btn-icon m-1 text-capitalize btn btn-outline-primary"
                                                onClick={() =>
                                                    navigate(
                                                        `/applications/view-application/${application.id}`
                                                    )
                                                }
                                            >
                                                <span className="ul-btn__icon">
                                                    <i className="i-Information"></i>
                                                </span>
                                                View
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}


                </div>
            </div>
            )}
        </div>
    );
};

export default DashboardTable;


// {
//     "data": [
//         {
//             "reference": "DeveDeem IS Demo Direct AT Demo Organization",
//             "address": "Living in Lahore, Faisalabad, Punjab",
//             "name": "Nadeem R Archer",
//             "id": 9,
//             "file": "http://techmac.ovh/rfmse/ReferenceFileUpload/Reference9",
//             "gender": "Male",
//             "disability": "No ",
//             "nationality": "Foreign National",
//             "contact": "+923012345678, +92515347372",
//             "email": "nademo@gmail.com, testnadeem@g.com",
//             "score": 0,
//             "graceScore": 0,
//             "resume": "http://techmac.ovh/rfmse/ReferenceFileUpload/Resume9",
//             "experience": "4",
//             "coverLetter": "Nadeem Statement",
//             "skills": "Nadeem skill",
//             "dob": "2023-12-21",
//             "network": "Linked In",
//             "totalScore": 0,
//             "fundingRequest": "1",
//             "education": "16"
//         },
//         {
//             "reference": "jjh IS yuu AT ytyt",
//             "address": "3Aj905hvflknh klre[oktregpijnm 12245----, Gakuch, Gilgit-Baltistan",
//             "name": "Muhammad  Mustafa",
//             "id": 23,
//             "file": "http://techmac.ovh/rfmse/ReferenceFileUpload/Reference23",
//             "gender": "Male",
//             "disability": "No ",
//             "nationality": "Foreign National",
//             "contact": "+923334383455",
//             "email": "mustafa-amjad@hotmail.com",
//             "score": 0,
//             "graceScore": 0,
//             "resume": "http://techmac.ovh/rfmse/ReferenceFileUpload/Resume23",
//             "experience": "4",
//             "coverLetter": "rtsytjhg hgjhjh hghgj ghh 4565  ytu 455666 5666 -- 00n hfgjh ",
//             "skills": "hjjytuyt ygyut n tyuyui ry t ",
//             "dob": "2222-02-22",
//             "network": "Website",
//             "totalScore": 0,
//             "fundingRequest": null,
//             "education": "16"
//         }
//     ],
//     "status": 200,
//     "message": "New Applications"
// }