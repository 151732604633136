import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import axios from 'axios';
import DashboardHeader from "./DashboardHeader";
import { NotificationContainer, NotificationManager, } from "react-notifications";


const PrivacyPolicyStatement = () => {
  const [errors, setErrors] = useState([]);
  const [checkbox1, setCheckbox1] = useState(false);
  const [checkbox2, setCheckbox2] = useState(false);
  const navigate = useNavigate();
  const auth = JSON.parse(localStorage.getItem("auth_user_info"));
  const applicantID = auth ? auth.id : null;


  const validate = async () => {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/applicant/submit`, {
      headers: {
        "accept": "*/*",
        "ApplicantId": applicantID,
        "Event": "validate",
        "SessionKey": "123"
      }
    });

    const errorProblems = response.data.data.problems.filter(problem => problem.includes('ERROR'));
    if (errorProblems.length > 0) {
      setErrors(errorProblems);
      return false;
    }

    return true;
  };


  const submit = async () => {
    //Submit Form Api
    if (await validate()) {
      await axios.get(`${process.env.REACT_APP_BASE_URL}/applicant/submit`, {
        headers: {
          "accept": "*/*",
          "ApplicantId": applicantID,
          "Event": "submit",
          "SessionKey": "123"
        }
      });
      setCheckbox1(false);
      setCheckbox2(false);
      NotificationManager.success("Your application has been submitted successfully.");

      //Fetch status
      const fetchData = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_BASE_URL}/applicant/get-application`, {
            headers: {
              'accept': '*/*',
              'ApplicantId': applicantID,
              'Event': 'basic',
              'SessionKey': '123'
            }
          });
          const data = await response.json();

          // Update the status property
          auth.status = data?.data?.status;

          // Stringify the JavaScript object back into a JSON string
          const updatedUserInfo = JSON.stringify(auth);

          // Set the item back into localStorage
          localStorage.setItem('auth_user_info', updatedUserInfo);

          // Navigate after the submit function has completed
          if (auth.status >= 4) {
            navigate('/application/status');
          }

        } catch (error) {
          console.error('Error fetching personal information data:', error);
        }
      };

      fetchData();
    }
  };

  return (
    <div>
      {/* Header Start */}
      <div className="mb-5">
        <DashboardHeader />
      </div>

      <div className="container row justify-content-center m-auto mb-5">
        <div className="col-md-10">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-4">
              <Navbar />
              {errors.length > 0 && (
                <div>
                  <h4>Information Missing</h4>
                  <ul className="list-group">
                    {errors.map((error, index) => (
                      <li className="list-group-item list-group-item-danger" key={index}>{error}</li>
                    ))}
                  </ul>
                </div>
              )}



            </div>
            <div className="col-sm-12 col-md-8 mb-4">
              <form>
                <div className="card ">
                  <div className="card-body">
                    <h4>Privacy Policy Statement</h4>
                    <p className="w-100">
                      Renewables First (RF) hereinafter referred to as "we," "us," or "our," is dedicated to safeguarding the privacy of your personal information. This Privacy Policy pertains to the collection, use, disclosure, and protection of your data when you use our online application platform. By using our services or submitting information to us, you consent to the practices described in this policy.
                    </p>

                    <ol className="p-0 privacy-formatting">
                      <li className="list-group-item">
                        <strong>1. Information We Collect</strong>
                        <div>
                          <strong>Application Data: </strong>We collect personal information you provide during the application process, which may include your name, email address, postal address, phone number, educational history, work experience, and other relevant details.
                        </div>
                        <div>
                          <strong>Usage Information:</strong> We gather data about how you use our online application platform, including IP address, browser type, operating system, pages visited, and time spent on the platform.

                        </div>
                        <div>
                          <strong>Cookies:</strong> We use cookies and similar technologies to track user interactions with our platform. You can manage cookie preferences using your browser settings.
                        </div>
                      </li>
                      <li className="list-group-item">
                        <strong>2. How We Use Your Information</strong>
                        <div>
                          We use the information you provide for the following purposes
                          <ol>
                            <li>To process and evaluate your application.</li>
                            <li>To communicate with you regarding your application status</li>
                            <li>To analyze and enhance the performance of our online application platform.</li>
                            <li>To meet legal and regulatory requirements.</li>
                          </ol>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <strong>3. Data Sharing and Disclosure</strong>
                        <div>
                          We do not share your personal information with third parties, except in the following cases:
                          <ol>
                            <li>With your explicit consent.</li>
                            <li>When required by law or to protect our legal rights.</li>
                          </ol>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <strong>4. Data Security</strong>
                        <div>
                          We employ industry-standard security measures to protect your data from unauthorized access or disclosure. However, no method of data transmission over the Internet or electronic storage is completely secure. We cannot guarantee the absolute security of your data.
                        </div>
                      </li>
                      <li className="list-group-item">
                        <strong>5. Your Choices</strong>
                        <div>
                          You have the right to access, update, or delete your application data. To exercise these rights, please contact us using the information below.

                        </div>
                        Certification: I certify that I completed this application myself, that the information given in this application is complete and accurate, and that I have carefully read and understand all notes and disclaimers provided therein. I understand that Renewables First reserves the right to verify all the information listed in the application. I understand that giving false or misleading information in the application will result in exclusion from the competition or immediate dismissal from the Margalla School on Energy and Climate Program.

                      </li>
                    </ol>


                    <p className="mb-4">Also, I acknowledge that I am aware of the following requirements that I must observe if I am selected for the program:</p>

                    <div className="ul-widget-app__task-list">
                      <label className="checkbox checkbox-primary">
                        <input type="checkbox" onChange={e => setCheckbox1(e.target.checked)} />
                        <strong><span>Must abide by all program rules and regulations. <span className="text-danger">*</span></span></strong>
                        <span className="checkmark"></span>
                      </label>
                    </div>

                    <div className="ul-widget-app__task-list mb-4">
                      <label className="checkbox checkbox-primary">
                        <input type="checkbox" onChange={e => setCheckbox2(e.target.checked)} />
                        <strong>
                          I will be available for the complete duration of the training in April 2024. <span className="text-danger">*</span>
                        </strong>
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="mc-footer">
                      <div className="row text-center">
                        <div className="col-lg-12 ">
                          <button type="button" className="btn btn-primary text-white m-1" onClick={submit} disabled={!checkbox1 || !checkbox2}>
                            SUBMIT
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>

            </div>
          </div >
        </div>
      </div>
      <NotificationContainer />
    </div >
  );
};

export default PrivacyPolicyStatement;
