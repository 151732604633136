import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import { NotificationContainer, NotificationManager, } from "react-notifications";
import DashboardHeader from "./DashboardHeader";

const AcademicInformation = () => {
  const [rows, setRows] = useState([{
    id: null,
    applicantId: null,
    name: 0,
    fos: null,
    institute: null,
    present: false,
    yearend: 0,
    yearstart: 0
  }]);
  const [degrees, setDegrees] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const auth = JSON.parse(localStorage.getItem("auth_user_info"));

  const applicantID = auth ? auth.id : null;

  // Years
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: currentYear - 1998 }, (v, i) => ({ id: i + 1, year: 1999 + i }));

  // Fetch degrees
  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/util/view-degree`, {
      method: 'GET',
      headers: { 'Accept': '*/*' }
    })
      .then(response => response.json())
      .then(data => setDegrees(data.data))
      .then(() => setLoading(false))
      .catch(error => console.error('Error:', error));
  }, []);

  // Fetch educational information
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/applicant/get-application`, {
          headers: {
            'accept': '*/*',
            'ApplicantId': applicantID,
            'Event': 'education',
            'SessionKey': '123'
          }
        });

        if (response.data.data) {
          setRows(response.data.data);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching personal information data:', error);
        NotificationManager.error(error.message, "Error");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Add a new row based on coming data fields
  const handleAddMore = () => {
    setRows([...rows, {
      id: null,
      applicantId: applicantID,
      name: 0,
      fos: null,
      institute: null,
      present: false,
      yearend: 0,
      yearstart: 0
    }]);
  };

  // Remove a row
  const handleRemove = (index) => {
    setRows(rows.filter((row, i) => i !== index));
  };

  // // Update input field values
  // const handleInputChange = (index, field, value) => {
  //   setRows(rows.map((row, i) => {
  //     if (i !== index) return row;
  //     return { ...row, [field]: value };
  //   }));
  // };

  // Update input field values
  const handleInputChange = (index, field, value) => {
    setRows(rows.map((row, i) => {
      if (i !== index) return row;
      let newRow = { ...row, [field]: value };
      if (field === 'yearstart') {
        newRow.yearend = newRow.yearend > value ? newRow.yearend : 0;
      } else if (field === 'yearend') {
        newRow.yearstart = newRow.yearstart < value ? newRow.yearstart : 0;
      }
      return newRow;
    }));
  };


  // // Update input field values
  // const handleInputChange = (index, field, value) => {
  //   setRows((prevRows) => {
  //     const updatedRows = prevRows.map((row, i) => {
  //       if (i !== index) return row;
  //       let updatedRow = { ...row, [field]: value };

  //       // Ensure End Year is greater than Start Year
  //       if (field === 'yearstart' && row.yearend && value > row.yearend) {
  //         updatedRow = { ...updatedRow, yearend: value };
  //       } else if (field === 'yearend' && row.yearstart && value < row.yearstart) {
  //         updatedRow = { ...updatedRow, yearstart: value };
  //       }

  //       return updatedRow;
  //     });

  //     return updatedRows;
  //   });
  // };

  // Post Education Request
  // let highestId = Math.max(...rows.map(row => row.id || 0));
  const handleSubmit = async (event) => {
    event.preventDefault();
    const academicData = rows.map((row, index) => ({
      applicantId: applicantID,
      degreeId: parseInt(row.name),
      fos: row.fos,
      institute: row.institute,
      present: row.present,
      yearEnd: parseInt(row.yearend),
      yearStart: parseInt(row.yearstart),
      id: row.id ? row.id : 0
    }));
    const keyToName = {
      applicantId: 'Applicant ID',
      degreeId: 'Degree',
      fos: 'Field of study',
      institute: 'Institute',
      present: 'Present',
      yearEnd: 'Year End',
      yearStart: 'Year Start',
      id: 'ID'
    }
    // console.log(academicData);
    for (let i = 0; i < academicData.length; i++) {
      for (let key in academicData[i]) {
        if (key !== 'present' && key !== 'yearEnd' && key !== 'id') {
          if (academicData[i][key] === '' || academicData[i][key] === null || academicData[i][key] === 0) {
            // console.log("Hello I am working" + academicData);
            NotificationManager.error(keyToName[key], "Field is required");
            return;
          }
        }
      }
    }

    const url = `${process.env.REACT_APP_BASE_URL}/applicant/academic`;

    try {
      const response = await axios.post(url, academicData, {
        headers: {
          'Accept': '*/*',
          'SessionKey': '123',
          'Content-Type': 'application/json'
        }
      });

      // Notify with the response message
      // NotificationManager.success(response.data.message, "Success");

      navigate("/application/professional-information");
    } catch (error) {
      if (error.response) {
        console.error('Error:', error.response.data);
        NotificationManager.error(error.response.data.message, "Error");
      } else if (error.request) {
        console.error('Error:', error.request);
        NotificationManager.error("No response received", "Error");
      } else {
        console.error('Error:', error.message);
        NotificationManager.error(error.message, "Error");
      }
    }
  };

  return (
    <div>
      {/* Header Start */}
      <div className="mb-5" >
        <DashboardHeader />
      </div>

      <div className="container row justify-content-center m-auto mb-5">
        <div className="col-md-10">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-4">
              <Navbar />
            </div>
            <div className="col-sm-12 col-md-8 mb-4">
              {loading ? (
                <div className="d-flex justify-content-center">
                  <div className="spinner-bubble spinner-bubble-primary m-5"></div>
                </div>

              ) : (
                <form onSubmit={handleSubmit}>
                  <div className="card">
                    <div className="card-body">
                      <h4>Academic Details</h4>
                      <p className='my-3'>Please proceed by providing your most recent academic information.</p>
                      <div className="row">
                        {rows.map((row, index) => (
                          <React.Fragment key={index}>
                            {/* <h5>{degrees.find(degree => degree.id === parseInt(row.name))?.name || "Please proceed by providing your most recent academic information."}</h5> */}
                            {/* Degree */}
                            <div className="form-group mb-3 col-sm-12 col-md-4">
                              <label htmlFor={`degreeId${index}`}>Degree <span className="text-danger">*</span></label>
                              <select
                                id={`degreeId${index}`}
                                className="form-control"
                                name="name"
                                value={row.name || 0}
                                onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                              >
                                <option key={0} value={0}>Please Select Degree</option>
                                {degrees.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            {/* Institute */}
                            <div className="form-group mb-3 col-sm-12 col-md-4">
                              <label htmlFor={`institute${index}`}>Institute <span className="text-danger">*</span></label>
                              <input
                                type="text"
                                className="form-control"
                                id={`institute${index}`}
                                placeholder="Enter Institute Name"
                                value={row.institute || ''}
                                onChange={(e) => handleInputChange(index, 'institute', e.target.value)}
                              />
                            </div>

                            {/* Field of Study */}
                            <div className="form-group mb-3 col-sm-12 col-md-4">
                              <label htmlFor={`fos${index}`}>Field of Study <span className="text-danger">*</span></label>
                              <input
                                type="text"
                                className="form-control"
                                id={`fos${index}`}
                                placeholder="Enter Field of Study"
                                value={row.fos || ''}
                                onChange={(e) => handleInputChange(index, 'fos', e.target.value)}
                              />
                            </div>

                            {/* Start Year */}
                            <div className="form-group mb-3 col-sm-12 col-md-4">
                              <label htmlFor={`yearStart${index}`}>Start Year <span className="text-danger">*</span></label>
                              <select
                                id={`yearStart${index}`}
                                className="form-control"
                                name="yearstart"
                                value={row.yearstart || 0}
                                onChange={(e) => handleInputChange(index, 'yearstart', e.target.value)}
                              >
                                <option key={0} value={0}>Select Start Year</option>
                                {years.map((year) => (
                                  <option key={year.id} value={year.year}>
                                    {year.year}
                                  </option>
                                ))}
                              </select>
                            </div>

                            {/* End Year */}
                            <div className="form-group mb-3 col-sm-12 col-md-4">
                              {row.present ? null : (<span>
                                <label htmlFor={`yearEnd${index}`}>End Year</label>
                                <select
                                  id={`yearEnd${index}`}
                                  className="form-control"
                                  name="yearend"
                                  value={row.yearend || 0}
                                  onChange={(e) => handleInputChange(index, 'yearend', e.target.value)}
                                >
                                  <option key={0} value={0}>Select End Year</option>
                                  {years.filter(year => year.year >= row.yearstart).map((year) => (
                                    <option key={year.id} value={year.year}>
                                      {year.year}
                                    </option>
                                  ))}
                                </select>
                              </span>
                              )}
                            </div>

                            {/* Present Checkbox */}
                            <div className="form-group mb-2 col-sm-12 col-md-4">
                              <div className="ul-widget-app__task-list mt-4 pt-2">
                                <label className="checkbox checkbox-primary ">
                                  <input type="checkbox" name="present" checked={row.present || false} onChange={(e) => handleInputChange(index, 'present', e.target.checked)} />
                                  <strong>
                                    On-going
                                  </strong>
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                              {/* <div className="mt-3">
                                <input
                                  type="checkbox"
                                  id={`presentcheck${index}`}
                                  name="present"
                                  className="mx-1  mt-4"
                                  checked={row.present || false}
                                  onChange={(e) => handleInputChange(index, 'present', e.target.checked)}
                                />
                                <label htmlFor={`presentcheck${index}`} className="d-inline-block mt-4">On-going</label>

                              </div> */}
                            </div>
                            {/* Remove Button */}
                            <div className="form-group mb-4 col-12">
                              {rows.length > 1 && (
                                <button type="button" className="btn btn-danger text-white mt-4" onClick={() => handleRemove(index)}>
                                  Remove
                                </button>
                              )}
                            </div>
                          </React.Fragment>
                        ))}
                      </div>
                      <div className="text-left">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={handleAddMore}
                        >
                          Add More
                        </button>
                      </div>
                    </div>
                    <div className="card-footer">
                      <div className="mc-footer">
                        <div className="row text-center">
                          <div className="col-lg-12 ">
                            <button type="submit" className="btn btn-primary text-white m-1">
                              Save & Next
                            </button>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
      <NotificationContainer />
    </div>
  );
};

export default AcademicInformation;

