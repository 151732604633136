import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NotificationContainer, NotificationManager, } from "react-notifications";

import axios from 'axios';

import Navbar from "./Navbar";
import DashboardHeader from "./DashboardHeader";
const PersonalInformation = () => {


  // Province API Service
  // State variables
  const [provinceData, setProvinceData] = useState();
  // const [provinceId, setProvinceId] = useState();
  // const [provinceCityData, setProvinceCityData] = useState(null);
  // const [cityData, setCityData] = useState([]);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(true);

  const auth = JSON.parse(localStorage.getItem("auth_user_info"));
  const applicantID = auth ? auth.id : null;

  const navigate = useNavigate();
  // Fetch personal information data and set it to formData state
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/applicant/get-application`, {
          headers: {
            'accept': '*/*',
            'ApplicantId': applicantID,
            'Event': 'basic',
            'SessionKey': '123'
          }
        });
        const data = await response.json();
        setFormData(data.data);
        // console.log(data.data.status);
        // if (data.data.provinceName) {
        //   setProvinceId(data.data.provinceName);
        // }
        // //console.log(data.data);
        setLoading(false);
        // //console.log(data.data)
      } catch (error) {
        console.error('Error fetching personal information data:', error);
      }
    };

    fetchData();

  }, []);

  // // Fetch city data initially and set it to cityData state
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch(`${process.env.REACT_APP_BASE_URL}/location/view-city`, {
  //         headers: {
  //           'accept': '*/*'
  //         }
  //       });
  //       const data = await response.json();
  //       setCityData(data);
  //       // //console.log(data.data);
  //       setLoading(false);
  //     } catch (error) {
  //       console.error('Error fetching city data:', error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  // Fetch province city data when the selected province changes
  // const handleProvinceChange = async (event = null, id = null) => {
  //   id = id ? id : (event && event.target ? event.target.value : provinceId);
  //   setProvinceId(id);

  //   if (id) {
  //     try {
  //       const response = await fetch(`${process.env.REACT_APP_BASE_URL}/location/view-city-by-province`, {
  //         headers: {
  //           'accept': '*/*',
  //           'ProvinceId': id
  //         }
  //       });
  //       const data = await response.json();
  //       setProvinceCityData(data);
  //       // console.log(data);
  //     } catch (error) {
  //       console.error('Error fetching province city data:', error);
  //     }
  //   }
  // };

  // Fetch province data when provinceId changes
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/location/view-province`, {
          headers: {
            'accept': '*/*',
          }
        });
        setProvinceData(response.data);
        // //console.log(response)
        setLoading(false);
      } catch (error) {
        console.error('Error fetching province data:', error);
      }
    };

    fetchData();
    // if (provinceId) {
    //   handleProvinceChange(provinceId);
    // }
  }, []);







  // Handle form field changes and update formData state accordingly
  const handleChange = (e) => {

    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Prepare your outgoing data
    const outgoingData = {
      addressDTO: {
        // cityId: isNaN(parseInt(formData.cityName)) ? '' : parseInt(formData.cityName),
        cityId: formData.cityName,
        streetAddress: formData.saddress,
        provinceId: parseInt(formData.provinceName),
        addressId: formData.addressId || 0
      },
      // birthCity: isNaN(parseInt(formData.birthCity)) ? '' : parseInt(formData.birthCity),
      contact1: formData.mobile2,
      dateOfBirth: formData.dob,
      disability: isNaN(parseInt(formData.disablity)) ? '' : parseInt(formData.disablity),
      email1: formData.email2,
      firstName: formData.fname,
      gender: formData.gender,
      id: formData.id,
      landline: formData.landline,
      lastName: formData.lname,
      middleName: formData.mname,
      nationality: formData.nationality === 'true'
    };

    // console.log(outgoingData);

    const keyToName = {
      cityId: 'City Address',
      streetAddress: 'Street Address',
      // birthCity: 'Birth City',
      provinceId: "Province",
      contact1: 'Secondary Phone Number',
      dateOfBirth: 'Date of Birth',
      disability: 'Disability',
      email1: 'Secondary Email',
      firstName: 'First Name',
      gender: 'Gender',
      id: 'ID',
      landline: 'Landline',
      lastName: 'Last Name',
      middleName: 'Middle Name',
      nationality: 'Nationality'
    };

    // Fields not required for validation
    const notRequired = ['contact1', 'email1', 'landline', 'middleName', 'birthCity'];

    // Check if all fields are provided
    for (let key in outgoingData) {
      // Skip if the field is not required
      if (notRequired.includes(key)) continue;

      if (typeof outgoingData[key] === 'object' && outgoingData[key] !== null) {
        // If the property is an object, check its properties
        for (let subKey in outgoingData[key]) {
          // Skip if the field is not required
          if (notRequired.includes(subKey)) continue;

          if (outgoingData[key][subKey] === '' || outgoingData[key][subKey] === null) {
            NotificationManager.error(keyToName[subKey], "Field is required");
            return;
          }
        }
      } else if (outgoingData[key] === '' || outgoingData[key] === null) {
        NotificationManager.error(keyToName[key], "Field is required");
        return;
      }
    }




    // Send the outgoingData to your API
    // console.log(outgoingData);

    const url = `${process.env.REACT_APP_BASE_URL}/applicant/info`;

    const options = {
      method: 'POST',
      headers: {
        'Accept': '*/*',
        'SessionKey': '123',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(outgoingData)
    };

    fetch(url, options)
      .then(response => {
        if (!response.ok) {
          return response.json().then(errorData => {
            console.error('outgoingData:', errorData);
            throw new Error('Something went wrong!');
          });
        }
        return response.json();
      })
      .then(data => {
        // //console.log('Data:', data);
      }).then(data => {
        // Use the navigate hook here

        navigate("/application/academic-information");
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };
  //console.log(provinceData)

  // Disablities
  // const disabilities = [
  //   { id: 1, name: 'Visual Impairment' },
  //   { id: 2, name: 'Hearing Impairment' },
  //   { id: 3, name: 'Mobility Impairment' },
  //   { id: 4, name: 'Cognitive Impairment' },
  //   { id: 5, name: 'Psychiatric Impairment' },
  //   { id: 6, name: 'No Disablility' },
  // ];

  // Genders
  const genders = [
    { id: 1, name: 'Male' },
    { id: 2, name: 'Female' },
    { id: 3, name: 'Other' },
    { id: 4, name: 'Prefer not to say' }
  ];

  return (

    <div>
      {/* Header Start */}
      <div className="mb-5">
        <DashboardHeader />
      </div>


      <div className="container row justify-content-center m-auto mb-5">
        <div className="col-md-10">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-4">
              <Navbar />
            </div>
            <div className="col-sm-12 col-md-8 mb-4">
              {/* <MyForm /> */}
              {loading ? (
                <div className="d-flex justify-content-center">
                  <div className="spinner-bubble spinner-bubble-primary m-5"></div>
                </div>

              ) : (
                <form onSubmit={handleSubmit}>
                  <div className="card">
                    <div className="card-body">
                      <h4>Personal Information</h4>
                      <h5>Basic Information</h5>
                      <div className="form-group mb-3">
                        <label htmlFor="firstName" className="">
                          First Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="firstName"
                          placeholder="Enter First Name"
                          name="fname"
                          value={formData?.fname || ''}
                          onChange={handleChange}

                        />
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="middleName" className="">
                          Middle Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="middleName"
                          name="mname"
                          placeholder="Enter Middle Name"
                          value={formData?.mname || ''}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="lastName" className="">
                          Last Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="lastName"
                          name="lname"
                          placeholder="Enter Last Name"
                          value={formData?.lname || ''}
                          onChange={handleChange}

                        />
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="gender" className="">
                          Gender <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-control"
                          id="gender"
                          name="gender"
                          placeholder="Select Gender"
                          value={formData?.gender || ''}
                          onChange={handleChange}

                        >
                          {(!formData?.gender || formData?.gender === null) && (
                            <option key={0} value={''}>
                              Select Gender
                            </option>
                          )}
                          {genders.map((gender) => (
                            <option key={gender.id} value={gender.name}>
                              {gender.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="dobirth" className="">
                          Date of Birth <span className="text-danger">*</span>
                        </label>
                        <input
                          type="Date"
                          className="form-control"
                          id="dobirth"
                          name="dob"
                          placeholder="Select Date of Birth"
                          value={formData?.dob || ''}
                          onChange={handleChange}

                        />
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="disabilities" className="">
                          Disability <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-control"
                          id="disabilities"
                          name="disablity"
                          placeholder="Select Disability"
                          value={formData?.disablity || 0}
                          onChange={handleChange}
                        >
                          {(!formData?.disablity || formData?.disablity === 0) && (
                            <option key={0} value={0}>
                              Select Disability
                            </option>
                          )}
                          <option value={1}>No</option>
                          <option value={2}>Yes</option>
                          {/* {disabilities.map((disability) => (
                            <option key={disability.id} value={disability.id}>
                              {disability.name}
                            </option>
                          ))} */}
                        </select>
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="nationality" className="">
                          Nationality <span className="text-danger">*</span>
                        </label>
                        <select
                          id="nationality"
                          className="form-control"
                          name="nationality"
                          placeholder=" Select Nationality"
                          value={formData?.nationality || 0}
                          onChange={handleChange}

                        >
                          <option key={0} value={0}>
                            Select Nationality
                          </option>

                          <option key={1} value={true}>
                            Pakistan
                          </option>
                          <option key={2} value={false}>
                            Other
                          </option>
                        </select>
                      </div>

                      <h5>Address</h5>
                      {/* <div className="form-group mb-3">
                        <label htmlFor="birthCityId" className="">
                          Place of Birth <span className="text-danger">*</span>
                        </label>
                        <select
                          id="birthCityId"
                          className="form-control"
                          name="birthCity"
                          placeholder="Select Place of Birth"
                          value={formData?.birthCity || 0}
                          onChange={handleChange}

                        >
                          <option key={0} value={0}>
                            Select Place of Birth
                          </option>
                          {cityData.data &&
                            cityData.data.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                        </select>
                      </div> */}

                      <div className="form-group mb-3">
                        <label htmlFor="province">Province <span className="text-danger">*</span></label>
                        <select
                          id="province"
                          className="form-control"
                          name="provinceName"
                          placeholder=" Select Province"
                          onChange={handleChange}
                          value={formData?.provinceName || 0}
                        >
                          {(!formData?.provinceName || formData?.provinceName === 0) && (
                            <option key={0} value={0}>
                              Select Province
                            </option>
                          )}

                          {provinceData &&
                            provinceData.data.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="pCity">City <span className="text-danger">*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          id="pCity"
                          name="cityName"
                          placeholder="Enter your city name"
                          value={formData?.cityName || ''}
                          onChange={handleChange}

                        />
                        {/* <select
                          id="pCity"
                          className="form-control"
                          name="cityName"
                          placeholder=" Select City"
                          value={formData?.cityName || 0}
                          onChange={handleChange}
                        >



                          {!provinceId ? (
                            <option key={0} value={0}>
                              Select Province First
                            </option>
                          ) : (
                            <>
                              <option key={0} value={0}>
                                Select your city
                              </option>
                              {provinceCityData &&
                                provinceCityData.data.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                ))
                              }
                            </>
                          )}

                        </select> */}
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="streetAddress" className="">
                          Address <span className="text-danger">*</span>
                        </label>

                        <input
                          type="text"
                          className="form-control"
                          id="streetAddress"
                          name="saddress"
                          placeholder="Enter Address"
                          value={formData?.saddress || ''}
                          onChange={handleChange}

                        />
                      </div>

                      <h5>Contact Information</h5>
                      <div className="form-group mb-3">
                        <label htmlFor="mobile1" className="">
                          Primary Phone Number <span className="text-danger">*</span>
                        </label>

                        <input
                          type="text"
                          className="form-control"
                          id="mobile1"
                          name="mobile1"
                          placeholder="+92300000000000"
                          defaultValue={formData?.mobile1 || ''}
                          disabled
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="mobile2" className="">
                          Secondary Phone Number
                        </label>

                        <input
                          type="text"
                          className="form-control"
                          id="mobile2"
                          name="mobile2"
                          placeholder="+92300000000000"
                          value={formData?.mobile2 || ''}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="landline" className="">
                          Landline
                        </label>

                        <input
                          type="text"
                          className="form-control"
                          id="landline"
                          name="landline"
                          placeholder="+92300000000000"
                          value={formData?.landline || ''}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="email1" className="">
                          Primary Email <span className="text-danger">*</span>
                        </label>

                        <input
                          type="text"
                          className="form-control"
                          id="email1"
                          name="email1"
                          placeholder="Student@gmail.com"
                          defaultValue={formData?.email1 || ''}
                          disabled
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="email2" className="">
                          Secondary Email
                        </label>

                        <input
                          type="text"
                          className="form-control"
                          id="email2"
                          name="email2"
                          placeholder="Student@gmail.com"
                          value={formData?.email2 || ''}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="card-footer">
                      <div className="mc-footer">
                        <div className="row text-center">
                          <div className="col-lg-12 ">

                            <button type="submit" className="btn btn-primary text-white m-1">
                              Save & Next
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>

      </div>

      {/* Main End */}
      <NotificationContainer />
    </div>
  );
};

export default PersonalInformation;