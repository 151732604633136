import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import DashboardHeader from './DashboardHeader';
import { NotificationContainer, NotificationManager, } from "react-notifications";

const Resume = () => {
    const [file, setFile] = useState(null);
    const navigate = useNavigate();
    const [inputKey, setInputKey] = useState(Date.now());
    const [formData, setFormData] = useState();
    const [loading, setLoading] = useState(true);
    const auth = JSON.parse(localStorage.getItem("auth_user_info"));
    const applicantID = auth ? auth.id : null;

    // useEffect(() => {
    //     console.log(file);
    // }, [file]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BASE_URL}/applicant/get-application`, {
            method: 'GET',
            headers: {
                'ApplicantId': applicantID,
                'Event': 'resume',
                'SessionKey': '123'
            }
        })
            .then(response => response.json())
            .then(data => {
                // console.log(data.data)
                if (data?.data) {
                    setFormData(data?.data)
                }
            })
            .then(() => setLoading(false))
            .catch(error => console.error(error));
        setLoading(false);
    }, []);

    const handleFileChange = (e) => {
        // console.log("I am working")
        if (e.target.files.length > 0) {
            if (e.target.files[0].size <= 5000000 && e.target.files[0].type === 'application/pdf') {
                setFile(e.target.files[0]);
            } else {
                NotificationManager.error("File must be a PDF and less than 5MB");
                setInputKey(Date.now()); // Reset the file input field
            }
        } else {
            NotificationManager.error('No file selected');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log(formData.path);

        if (formData?.path && !file) {
            navigate('/application/personal-statement');

        } else {
            if (!file) {
                NotificationManager.error("Resume is required", "No file selected")
                return;
            }

            // Upload file API
            const subFormData = new FormData();
            subFormData.append('file', file);
            await fetch(`${process.env.REACT_APP_BASE_URL}/util/upload`, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'ApplicantId': applicantID,
                    'Event': 'resume',
                    'SessionKey': '123',
                },
                body: subFormData
            });

            // Navigate to next page
            navigate('/application/personal-statement');
        }
    };


    return (
        <div>
            {/* Header Start */}
            <div className="mb-5">
                <DashboardHeader />
            </div>

            <div className="container row justify-content-center m-auto mb-5">
                <div className="col-md-10">
                    <div className="row justify-content-center">
                        <div className="col-sm-12 col-md-4">
                            <Navbar />
                        </div>

                        <div className="col-sm-12 col-md-8 mb-4">
                            {loading ? (
                                <div className="d-flex justify-content-center">
                                    <div className="spinner-bubble spinner-bubble-primary m-5"></div>
                                </div>

                            ) : (
                                <form onSubmit={handleSubmit}>
                                    <div className="card">
                                        <div className="card-body">
                                            <h4>Resume</h4>
                                            <div className="row">

                                                <label htmlFor="file">Upload Resume  <span className="text-danger">*</span></label>
                                                <div className="input-group mb-3 col-12">
                                                    <input type="file" className="form-control" id="file" onChange={handleFileChange} key={inputKey} accept=".pdf" />
                                                    <label className="input-group-text" htmlFor="file">Upload</label>
                                                </div>
                                                <div className="input-group mb-3 col-12">
                                                    {formData && <button type="button" className="btn btn-outline-primary m-1" onClick={() => window.open(formData.path, '_blank')}>View</button>}

                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <div className="mc-footer">
                                                <div className="row text-center">
                                                    <div className="col-lg-12">
                                                        <button type="submit" className="btn btn-primary text-white m-1">Save & Next</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            )}
                        </div>

                    </div>
                </div>
            </div>
            <NotificationContainer />
        </div>

    )

};

export default Resume;
