import React from 'react'

import AdminNav from "./AdminNav";
import DashboardHeader from "./DashboardHeader";
import DashboardSearch from './DashboardSearch';
import DashboardTable from './DashboardTable';


const UnderReviewApplications = () => {

  const handleSearch = (searchFields) => {
    // Call your search API here with the searchFields
    console.log(searchFields);
  }

  return (

    <div className="app-admin-wrap layout-sidebar-large">
      <DashboardHeader />
      <AdminNav />
      <div className="main-content-wrap d-flex flex-column sidenav-open">
        <h1>UnderReviewApplication</h1>
        <DashboardSearch onSearch={handleSearch} />


        <DashboardTable event={'review'} title={'UnderReviewApplication'} />

      </div>
    </div>
  )
}

export default UnderReviewApplications


