import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AdminNav from "./AdminNav";
import DashboardHeader from "./DashboardHeader";
import { Row, Col, Tab, Tabs } from "react-bootstrap";
import SimpleCard from "@gull/components/cards/SimpleCard";

const ViewApplication = () => {
    const { id } = useParams();
    const [education, setEducation] = useState([]);
    const [employment, setEmployment] = useState([]);
    const [fields, setFields] = useState({});
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState("");


    // const auth = JSON.parse(localStorage.getItem("auth_user_info"));

    // const applicantID = auth ? auth.id : null;

    const handleInputChange = (event) => {
        setFields({
            ...fields,
            [event.target.name]: event.target.value
        });
    };

    const fetchData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/applicant/view`, {
                headers: {
                    'accept': '*/*',
                    'ApplicantId': id,
                    'SessionKey': '123'
                }
            });
            const data = await response.json();
            setEducation(data?.data?.education);
            setEmployment(data?.data?.professional);
            setFields(data?.data?.fields);
            setMessage(data?.message);
            setLoading(false);

        } catch (error) {
            console.error('Error fetching personal information data:', error);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData();
    }, []);

    const handleButtonClick = async (event) => {
        const eventName = event.target.innerText.toLowerCase();

        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/applicant/score`, {
            method: 'PUT',
            headers: {
                'Accept': '*/*',
                'Event': eventName,
                'SessionKey': '123',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                applicantId: parseInt(id),
                graceScore: parseInt(fields.graceScore),
                score: parseInt(fields.score)
            })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        // console.log(data);
        fetchData();
    }




    // Email and contact
    let emails = fields && fields.email ? fields.email.split(', ') : ['N/A'];
    let contacts = fields && fields.contact ? fields.contact.split(', ') : ['N/A', 'N/A', 'N/A'];

    // Remove 'L:' from the landline number
    if (contacts[2]) {
        contacts[2] = contacts[2].replace('L:', '');
    }
    // Text Formatting
    function formatText(text) {
        let lines = text.split('\n');
        let formattedText = lines.map(line => `<p>${line}</p>`).join('');
        return formattedText;
    }

    let coverLettertext = fields && fields.coverLetter ? formatText(fields.coverLetter) : 'N/A';
    let formattedCoverLetter = formatText(coverLettertext);

    let skillsText = fields && fields.skills ? fields.skills : 'N/A';
    let skillsTextFormatted = formatText(skillsText);

    // let fundingText = fields && fields.fundingRequest ? fields.fundingRequest : 'N/A';
    // let fundingTextFormatted = formatText(fundingText);

    let fundingStatus = fields && fields.fundingRequest
        ? (fields.fundingRequest === "1" ? "Funding is required" : "Funding is not required")
        : "No fields object or funding request field";

    // Styles:
    const styles = {
        display: 'inline-block',
        maxWidth: '100px',
        fontWeight: 700,
        height: '40px',
        fontSize: '12pt',
        lineHeight: 1.2,
    };
    let displayStyle = { display: styles.display };
    return (
        <div className="app-admin-wrap layout-sidebar-large">
            <DashboardHeader />
            <AdminNav />
            <div className="main-content-wrap d-flex flex-column sidenav-open">
                {
                    loading ? (
                        <div className="d-flex justify-content-center">
                            <div className="spinner-bubble spinner-bubble-primary m-5"></div>
                        </div>

                    ) : (
                        <div className="mb-4">
                            <h1>{message}</h1>
                            <SimpleCard title={`EVALUATION`} className="mb-4">
                                <Row className='justify-content-center'>
                                    <Col lg={6} md={6} sm={12} xs={12}>

                                        <div className='row justify-content-between mb-2'>
                                            <h5 style={displayStyle} className="text-capitalize mb-1 col-6">Assign Score:</h5>
                                            <input
                                                style={styles}
                                                type="number"
                                                name="score"
                                                value={fields?.score}
                                                onChange={handleInputChange}
                                                className='form-control col-6'
                                            />
                                        </div>
                                        <div className='row justify-content-between mb-2'>
                                            <h5 style={displayStyle} className="text-capitalize mb-1 col-6">Grace Score:</h5>
                                            <input
                                                style={styles}
                                                type="number"
                                                name="graceScore"
                                                value={fields?.graceScore}
                                                onChange={handleInputChange}
                                                className='form-control col-6'
                                            />
                                        </div>
                                        <div className='row justify-content-between mb-4'>
                                            <h5 style={displayStyle} className="text-capitalize mb-1 col-6">Total Score:</h5>
                                            <input style={styles} type="number" value={fields && fields?.totalScore ? fields?.totalScore : 0} className='form-control col-6' disabled />
                                        </div>

                                        <div className="text-center">
                                            <button style={styles} type="button" className="m-1 text-capitalize btn btn-success text-white" onClick={handleButtonClick}>Select</button>
                                            <button style={styles} type="button" className="m-1 text-capitalize btn btn-outline-primary" onClick={handleButtonClick}>Save</button>
                                            <button style={styles} type="button" className="m-1 text-capitalize btn btn-danger text-white" onClick={handleButtonClick}>Reject</button>
                                        </div>
                                    </Col>
                                </Row>
                            </SimpleCard>
                            <Row>
                                <Col lg={12} md={12} sm={12} xs={12}>

                                    <SimpleCard title={`${fields && fields.name ? fields.name : 'N/A'}`}>

                                        <Tabs defaultActiveKey="home" id="home">
                                            <Tab eventKey="home" title="BASIC INFROMATION">
                                                <div className="row">
                                                    <p className='col-6'><strong>Name:</strong> {fields && fields.name ? fields?.name : 'N/A'}</p>
                                                    {/* <div className='col-6 row'> */}
                                                    <p className='col-6'><strong>Primary Email:</strong> {emails[0]}</p>
                                                    <p className='col-6'><strong>Secondary Email:</strong> {emails[1] ? emails[1] : 'N/A'}</p>
                                                    {/* </div> */}
                                                    {/* <div className='col-6 row'> */}
                                                    <p className="col-6"><strong>Disability:</strong> {fields && fields.disability ? fields.disability : 'N/A'}</p>

                                                    <p className='col-6'><strong>Primary Contact:</strong> {contacts[0]}</p>
                                                    <p className='col-6'><strong>Secondary Contact:</strong> {contacts[1] !== 'N/A' ? contacts[1] : 'N/A'}</p>
                                                    <p className='col-6'><strong>Landline:</strong> {contacts[2] !== 'N/A' ? contacts[2] : 'N/A'}</p>
                                                    {/* </div> */}
                                                    <p className='col-6'><strong>Gender:</strong> {fields && fields.gender ? fields.gender : 'N/A'}</p>
                                                    <p className='col-6'><strong>Nationality:</strong> {fields && fields.nationality ? fields.nationality : 'N/A'}</p>
                                                    <p className='col-6'><strong>Education:</strong> {fields && fields.education ? fields.education : 'N/A'} Years</p>
                                                    <p className='col-6'><strong>Experience:</strong> {fields && fields.experience ? fields.experience : 'N/A'} Years</p>
                                                    <p className='col-6'><strong>Date of Birth:</strong> {fields && fields.dob ? fields.dob : 'N/A'}</p>
                                                    <p className='col-6'><strong>Address:</strong> {fields && fields.address ? fields.address : 'N/A'}</p>
                                                </div>
                                            </Tab>
                                            <Tab eventKey="academic" title="ACADEMIC">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Institute</th>
                                                            <th scope="col">Degree</th>
                                                            <th scope="col">Tenure </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {education && education.map((edu, index) => (
                                                            <tr key={index}>
                                                                <th scope="row">{edu?.institute || 'N/A'}</th>
                                                                <td>{edu?.degree || 'N/A'}</td>
                                                                <td>{edu?.tenure || 'N/A'}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </Tab>
                                            <Tab eventKey="profile" title="PROFESSIONAL">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Organization</th>
                                                            <th scope="col">Job</th>
                                                            <th scope="col">Tenure </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {employment && employment.map((employ, index) => (
                                                            <tr key={index}>
                                                                <th scope="row">{employ?.organization || 'N/A'}</th>
                                                                <td>{employ?.job || 'N/A'}</td>
                                                                <td>{employ?.tenure || 'N/A'}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </Tab>
                                            <Tab eventKey="resume" title="RESUME">
                                                <p><strong>Resume:</strong> <br />{fields && fields?.resume ? 'Resume Attached' : 'N/A'}</p>

                                                <div>
                                                    {fields?.resume && <button type="button" className="btn btn-outline-primary m-1" onClick={() => window.open(fields?.resume, '_blank')}>View</button>}
                                                </div>
                                            </Tab>
                                            <Tab eventKey="contact" title="PERSONAL STATEMENT">
                                                <p><strong>How did you hear about this fellowship opportunity?:</strong> <br />{fields && fields?.network ? fields?.network : 'N/A'}</p>
                                                <strong>Cover Letter: </strong>
                                                <div dangerouslySetInnerHTML={{ __html: formattedCoverLetter }} />
                                                <strong className='mb-3'>Skills: { }</strong>
                                                <div dangerouslySetInnerHTML={{ __html: skillsTextFormatted }} />
                                            </Tab>
                                            <Tab eventKey="reference" title="REFERENCE LETTER">
                                                <p><strong>Reference:</strong> <br />{fields && fields.reference ? fields.reference : 'N/A'}</p>

                                                <div>
                                                    {fields?.file && <button type="button" className="btn btn-outline-primary m-1" onClick={() => window.open(fields?.file, '_blank')}>View</button>}
                                                </div>
                                            </Tab>
                                            <Tab eventKey="funding" title="FUNDING">
                                                <strong className='mb-3'>Funding Request: </strong>
                                                <br />
                                                <p>{fundingStatus}</p>

                                            </Tab>
                                        </Tabs>
                                    </SimpleCard>
                                </Col>
                            </Row>
                        </div>
                    )}
            </div>
        </div>
    );
};

export default ViewApplication;
