// import React, { Component, Fragment } from "react";
// import { connect } from "react-redux";
// import AppContext from "app/appContext";
// import GullLayout from "app/GullLayout/GullLayout";
// import { flatMap } from "lodash";
// class AuthGuard extends Component {
//   constructor(props, context) {
//     super(props);
//     let { routes } = context;

//     this.state = {
//       authenticated: true,
//       routes,
//     };
//   }

//   componentDidMount() {
//     this.setState({
//       routes: flatMap(this.state.routes, (item) => {
//         if (item.routes) {
//           return [...item.routes];
//         }
//         return [item];
//       }),
//     });

//     if (!this.state.authenticated) {
//       this.redirectRoute(this.props);
//     }
//   }

//   componentDidUpdate() {
//     if (!this.state.authenticated) {
//       this.redirectRoute(this.props);
//     }
//   }

//   shouldComponentUpdate(nextProps, nextState) {
//     return nextState.authenticated !== this.state.authenticated;
//   }

//   static getDerivedStateFromProps(props, state) {
//     const { location, user } = props;
//     const { pathname } = location;
//     const matched = state.routes.find((r) => r.path === pathname);
//     const authenticated =
//       matched && matched.auth && matched.auth.length
//         ? matched.auth.includes(user.role)
//         : true;

//     return {
//       authenticated,
//     };
//   }

//   redirectRoute(props) {
//     const { location, history } = props;
//     const { pathname } = location;

//     history.push({
//       pathname: "/session/signin",
//       state: { redirectUrl: pathname },
//     });
//   }

//   render() {
//     let { route } = this.props;
//     const { authenticated } = this.state;

//     return authenticated ? (
//       <Fragment>
//         <GullLayout route={route}></GullLayout>
//       </Fragment>
//     ) : null;
//   }
// }

// AuthGuard.contextType = AppContext;

// const mapStateToProps = (state) => ({
//   user: state.user,
// });

// export default connect(mapStateToProps)(AuthGuard);

// import { flat } from "@utils";
// import routes from "app/routes";
// import { useSelector } from "react-redux";
// import GullLayout from "app/GullLayout/GullLayout";
// import React, { useState, useEffect } from "react";
// import { Outlet, redirect, useLocation } from "react-router-dom";
// import Dashboard from "app/views/sessions/Dashboard";

// const getUserRoleAuthStatus = (pathname, user, routes) => {
//   console.log(user, pathname, routes);
//   if (!user) {
//     return false;
//   }
//   const matched = routes.find((r) => r.path === pathname);

//   const authenticated =
//     matched && matched.auth && matched.auth.length
//       ? matched.auth.includes(user.role)
//       : true;

//   return authenticated;
// };

// const AuthGuard = () => {
//   const { user, isAuthenticated } = useSelector((state) => state.auth);

//   const { pathname } = useLocation();
//   const [previouseRoute, setPreviousRoute] = useState(null);

//   const isUserRoleAuthenticated = getUserRoleAuthStatus(
//     pathname,
//     user,
//     flat(routes.routes)
//   );
//   let authenticated = isAuthenticated && isUserRoleAuthenticated;

//   // IF YOU NEED ROLE BASED AUTHENTICATION,
//   // UNCOMMENT ABOVE TWO LINES, getUserRoleAuthStatus METHOD AND user VARIABLE
//   // AND COMMENT OUT BELOW LINE

//   // let authenticated = isAuthenticated

//   useEffect(() => {
//     if (previouseRoute !== null) setPreviousRoute(pathname);
//   }, [pathname, previouseRoute]);

//   return authenticated ? (
//     <Dashboard />
//     // <GullLayout>
//     //   <Outlet />
//     // </GullLayout>
//   ) : (
//     redirect("/login")
//   );
// };

// export default AuthGuard;



// import { useSelector } from "react-redux";






import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const AuthGuard = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [navTarget, setNavTarget] = useState(null);
  const auth = JSON.parse(localStorage.getItem("auth_user_info"));

  useEffect(() => {
    const ignoredRoutes = [
      "/signup",
      "/signin",
      "/sessions/forgot-password",
    ];

    if (auth && ignoredRoutes.some(route => location.pathname.includes(route))) {
      if (auth.type === 2) {
        if (auth.status === 1) {
          setNavTarget("/signup");
        } else {
          setNavTarget("/application/instructions");
        }
      } else if (auth.type === 1) {
        setNavTarget("/dashboard");
      }
      setIsLoading(false);
      return;
    }

    if (ignoredRoutes.some(route => location.pathname.includes(route))) {
      setIsLoading(false);
      return;
    }

    if (!auth || auth.status === 1) {
      if (location.pathname !== "/signin") {
        setNavTarget("/signin");
        setIsLoading(false);
      }
    } else {
      // Admin Routes
      const adminRoutes = [
        "/dashboard",
        "/applications/new-applications",
        "/applications/approved-applications",
        "/applications/under-review-applications",
        "/applications/rejected-applications",
        "/applications/view-application",
        "/applicant/report/not-submitted",
        "/applicant/report/signup",
        "/applicant/report/male",
        "/applicant/report/female",
        "/applicant/report/punjab",
        "/applicant/report/sindh",
        "/applicant/report/balochistan",
        "/applicant/report/kpk",
        "/applicant/report/isb",
        "/applicant/report/gb",
        "/applicant/report/ak"
      ];

      // User Routes
      const userRoutes = [
        "/application/instructions",
        "/application/personal-information",
        "/application/academic-information",
        "/application/professional-information",
        "/application/personal-statement",
        "/application/reference-letter",
        "/application/funding-options",
        "/application/privacy-policy-statement",
        "/application/resume"
      ];

      const applicationStatusRoutes = [
        "/application/status",
      ]

      // if (auth.type === 1 && userRoutes.some(route => location.pathname.includes(route)) && location.pathname !== "/error/404") {
      //   navigate("/error/404");
      // } else if (auth.type === 2 && adminRoutes.some(route => location.pathname.includes(route)) && location.pathname !== "/error/404") {
      //   navigate("/error/404");
      // } else if (!adminRoutes.concat(userRoutes).some(route => location.pathname.includes(route)) && location.pathname !== "/error/404") {
      //   navigate("/error/404");
      // }

      if (auth.type === 1 && (userRoutes.concat(applicationStatusRoutes).some(route => location.pathname.includes(route))) && location.pathname !== "/error/404") {
        navigate("/error/404");
      } else if (auth.type === 2 && auth.status >= 4 && (userRoutes.concat(adminRoutes).some(route => location.pathname.includes(route))) && location.pathname !== "/error/404") {
        navigate("/error/404");
      } else if (auth.type === 2 && auth.status < 4 && (adminRoutes.concat(applicationStatusRoutes).some(route => location.pathname.includes(route))) && location.pathname !== "/error/404") {
        navigate("/error/404");
      } else if (!adminRoutes.concat(userRoutes).concat(applicationStatusRoutes).some(route => location.pathname.includes(route)) && location.pathname !== "/error/404") {
        navigate("/error/404");
      }
      // After auth check is complete, set loading state to false
      setIsLoading(false);
    }
  }, [location]);

  useEffect(() => {
    if (navTarget) {
      navigate(navTarget);
    }
  }, [navTarget]);

  if (isLoading) {
    return <div className="d-flex justify-content-center">
      <div className="spinner-bubble spinner-bubble-primary m-5"></div>
    </div>; // Replace with your actual loading component
  }

  return children;
};

export default AuthGuard;
