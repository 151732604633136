import React from "react";
import AdminNav from "./AdminNav";
import DashboardHeader from "./DashboardHeader";
// import DashboardSearch from './DashboardSearch';
import DashboardTable from './DashboardTable';


function UnsubmittedReport() {
    return (
        <>
            <div className="app-admin-wrap layout-sidebar-large">
                <DashboardHeader />
                <AdminNav />
                <div className="main-content-wrap d-flex flex-column sidenav-open">
                    <h1>Not Submitted Applications</h1>

                    {/* <DashboardSearch onSearch={handleSearch} /> */}


                    <DashboardTable title={'Not Submitted Applications'} event={'unsubmitted'} type={"report"} />

                </div>
            </div>
        </>
    );
}

export default UnsubmittedReport;