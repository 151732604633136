import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const [title, setTitle] = useState('Personal Information');
  const location = useLocation();

  useEffect(() => {
    // Update the title when the route changes
    const session = sessions.find(session => session.path === location.pathname);
    if (session) {
      setTitle(session.title);
    }
  }, [location.pathname]);

  const sessions = [
    { id: 0, title: 'Instructions', path: '/application/instructions' },
    { id: 1, title: 'Personal Information', path: '/application/personal-information' },
    { id: 2, title: 'Academic Details', path: '/application/academic-information' },
    { id: 3, title: 'Professional Details', path: '/application/professional-information' },
    { id: 4, title: 'Resume', path: '/application/resume' },
    { id: 5, title: 'Personal Statement', path: '/application/personal-statement' },
    { id: 6, title: 'Reference Letter', path: '/application/reference-letter' },
    { id: 7, title: 'Funding Options', path: '/application/funding-options' },
    { id: 8, title: 'Privacy Policy Statement', path: '/application/privacy-policy-statement' },
    // { id: 8, title: 'Results', path: '/application/status' },
  ];


  return (
    <nav>
      <div>
        {/* <a className="logo" href="http://startific.com"></a> */}
      </div>
      <div className="mb-4">
        <div className="card text-left p-1">
          <div className="card-body p-3">
            <h4 className="card-title mb-2">{title}</h4>
            <div className="list-group">
              {sessions.map(session => (
                <Link
                  key={session.id}
                  onClick={() => setTitle(session.title)}
                  to={session.path}
                  className={`list-group-item list-group-item-action ${location.pathname === session.path ? 'active' : ''}`}
                >
                  {session.title}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;