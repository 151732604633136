import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PieChart from "app/views/charts/echarts/PieChart";
import SimpleCard from "@gull/components/cards/SimpleCard";
import LineChart3 from "app/views/charts/echarts/LineChart3";
import ComparisonChart from "app/views/charts/echarts/ComparisonChart";


const DashboardView = () => {

    const [data, setData] = useState(null);
    const [recentSignUp, setRecentSignUp] = useState([]);
    const [unSubmitted, setUnSubmitted] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/applicant/report`, {
                headers: {
                    'Event': 'dashboard',
                    'SessionKey': '123'
                }
            });
            setData(response?.data?.data);
        };
        fetchData();


        async function getNewApplications() {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/applicant/get-application-list`, {
                    headers: {
                        'accept': '*/*',
                        'Event': 'new',
                        'SessionKey': '123'
                    }
                });

                // Sort the data based on the 'id' in descending order
                const sortedData = response?.data?.data?.sort((a, b) => b.id - a.id);

                // Get the top 10 records
                const recentSignUp = sortedData?.slice(0, 10);

                setRecentSignUp(recentSignUp);
            } catch (error) {
                console.error(error);
            }
        }

        getNewApplications();
        async function getUnsubmitted() {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/applicant/get-application-list`, {
                    headers: {
                        'accept': '*/*',
                        'Event': 'selected',
                        'SessionKey': '123'
                    }
                });

                // Sort the data based on the 'id' in descending order
                const sortedData = response?.data?.data?.sort((a, b) => b.id - a.id);

                // Get the top 10 records
                const unSubmitted = sortedData?.slice(0, 10);

                setUnSubmitted(unSubmitted);
            } catch (error) {
                console.error(error);
            }
        }

        getUnsubmitted();

    }, []);
    // Prepare the data for the PieChart
    let applicationByProvince = [
        { value: data?.totalApplicantPunjab, name: "Punjab" },
        { value: data?.totalApplicantSindh, name: "Sindh" },
        { value: data?.totalApplicantKpk, name: "Khyber Khyber Pakhtunkhwa" },
        { value: data?.totalApplicantBalochistan, name: "Blochistan" },
        { value: data?.totalApplicantIslamabad, name: "Islamabad" },
        { value: data?.totalApplicantGilgit, name: "Gilgit-Baltistan" },
        { value: data?.totalApplicantKashmir, name: "Azad Kashmir" }
    ];


    // For gender data, you can create separate arrays for male and female applicants
    let applicationByGender = [
        { value: data?.totalAppliedMale, name: "Male" },
        { value: data?.totalAppliedFemale, name: "Female" }
    ];
    let state = {
        cardList1: data && [
            {
                icon: "i-Add-User",
                title: data.totalApplied.toString(),
                subtitle: "TOTAL APPLICATIONS",
            },
            {
                icon: "i-Add-User",
                title: data.totalAppliedMale.toString(),
                subtitle: "TOTAL Applied Male",
            },
            {
                icon: "i-Add-User",
                title: data.totalAppliedFemale.toString(),
                subtitle: "TOTAL Applied Female",
            },
            {
                icon: "i-Financial",
                title: data.incomplete.toString(),
                subtitle: "INCOMPLETE",
            },
            {
                icon: "i-Checkout-Basket",
                title: data.accepted.toString(),
                subtitle: "APPROVED",
            },
            {
                icon: "i-Money-2",
                title: data.rejected.toString(),
                subtitle: "REJECTED",
            },
            {
                icon: "i-Money-2",
                title: data.verified.toString(),
                subtitle: "ELIGIBLE APPLICANT",
            },
            {
                icon: "i-Money-2",
                title: data.underReview.toString(),
                subtitle: "UNDER REVIEW",
            },
        ],

        newApplications: [
            {
                newApplicantList: [
                    {
                        applicantName: "Joh Doe",
                        applicantAge: 26,
                        applicantLocation: "Lahore",
                        applicantScore: 9,
                    },
                    {
                        applicantName: "Joh Doe",
                        applicantAge: 26,
                        applicantLocation: "Lahore",
                        applicantScore: 9,
                    }, {
                        applicantName: "Joh Doe",
                        applicantAge: 26,
                        applicantLocation: "Lahore",
                        applicantScore: 9,
                    },

                ],
            },

        ],
    };

    // const getUserStatusClass = (status) => {
    //     switch (status) {
    //         case "active":
    //             return "badge-success";
    //         case "inactive":
    //             return "badge-warning";
    //         case "pending":
    //             return "badge-primary";
    //         default:
    //             break;
    //     }
    // };

    let {
        cardList1 = [],
        newApplications = [],
    } = state;

    return (
        <div className="main-content-wrap d-flex flex-column sidenav-open">


            {/* <Breadcrumb
                routeSegments={[
                    { name: "Dashboard", path: "/dashboard" },
                    { name: "Version 1" },
                ]}
            ></Breadcrumb> */}
            <div className="row">
                {cardList1 && cardList1.map((card, index) => (
                    <div key={index} className="col-lg-3 col-md-6 col-sm-6">
                        <div className="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                            <div className="card-body">
                                {/* <i className={card.icon}></i> */}
                                <div className="content custom w-100 p-3">
                                    <h1 className="text-primary text-30 text-right mb-2 text-capitalize">
                                        {card.title}
                                    </h1>
                                    <h5 className="text-muted text-left mt-2 mb-0 text-uppercase">
                                        {card.subtitle}
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className="row">
                {/* <div className="col-lg-8 col-md-12">
                    <SimpleCard title="This Year Applications" className="mb-4">
                        <ComparisonChart height="260px"></ComparisonChart>
                    </SimpleCard>
                </div> */}
                <div className="col-lg-6 col-sm-12">
                    <SimpleCard title="Apllication by Province" className="mb-4">
                        <PieChart height="260px" data={applicationByProvince}></PieChart>
                    </SimpleCard>
                </div>
                <div className="col-lg-6 col-sm-12">
                    <SimpleCard title="Apllication by Gender" className="mb-4">
                        <PieChart height="260px" data={applicationByGender}></PieChart>
                    </SimpleCard>
                </div>
                {/* <div className="col-lg-6 col-sm-12">
                    <SimpleCard title="Apllication by Province" className="mb-4">
                        <PieChart height="260px"></PieChart>
                    </SimpleCard>
                </div> */}
            </div>

            <div className="row">
                <div className="col-lg-6 col-md-12">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card mb-4">
                                <div className="card-body p-0 " style={{ overflow: "hidden" }}>
                                    <div className="card-title border-bottom d-flex align-items-center bg-primary m-0 p-3">
                                        <h3 className="mb-0 text-white">New Applications</h3>
                                        <span className="flex-grow-1"></span>
                                        <span className="badge badge-pill badge-warning">
                                            Updated daily
                                        </span>
                                    </div>
                                    {recentSignUp && recentSignUp.map((item, index) => (
                                        <div
                                            key={index}
                                            className="d-flex row border-bottom justify-content-between p-3"
                                        >

                                            <div key={item.id} >


                                                <table style={{ borderCollapse: 'collapse' }} className='w-100'>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ border: 'none' }}><h5 className="m-0">{item?.name}</h5></td>
                                                            <td style={{ border: 'none', textAlign: "right" }}><span className="text-small text-muted">{item?.id}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2} style={{ border: 'none' }}> <p>{item?.address}</p> </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            {/* {newApplicantList.map((item, i) => (
                                                <div key={i} className="col-6">
                                                    <span className="text-small text-muted">
                                                        {item.applicantName}
                                                    </span>
                                                    <h5 className="m-0">{item.applicantAge}</h5>
                                                </div>
                                            ))} */}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card mb-4">
                                <div className="card-body p-0" style={{ overflow: "hidden" }}>
                                    <div className="card-title border-bottom d-flex align-items-center bg-primary  m-0 p-3">
                                        <h3 className="mb-0 text-white">Publish Applications</h3>
                                        <span className="flex-grow-1"></span>
                                        <span className="badge badge-pill badge-warning">
                                            Updated daily
                                        </span>
                                    </div>
                                    {unSubmitted && unSubmitted.map((item, index) => (
                                        <div
                                            key={index}
                                            className="d-flex row border-bottom justify-content-between p-3"
                                        >
                                            <div key={item.id} >


                                                <table style={{ borderCollapse: 'collapse' }} className='w-100'>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ border: 'none' }}><h5 className="m-0">{item?.name}</h5></td>
                                                            <td style={{ border: 'none', textAlign: "right" }}><span className="text-small text-muted">{item?.status}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2} style={{ border: 'none' }}> <p>{item?.address}</p> </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="col-md-12">
                    <div className="card mb-4">
                        <div className="card-body card-title mb-0">
                            <h3 className="m-0">Last 20 Day Applications</h3>
                        </div>
                        <LineChart3 height="360px"></LineChart3>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="card mb-4">

                    </div>
                </div>

            </div>
        </div>
    );
};

export default DashboardView;
