import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { NotificationContainer, NotificationManager, } from "react-notifications";
import DashboardHeader from "./DashboardHeader";


const GreenEnergyCommitment = () => {
  const [/*commitmentData*/, setCommitmentData] = useState({});
  const [skills, setSkills] = useState("");
  const [content, setContent] = useState("");
  const [referalData, setReferalData] = useState(null);
  const [referalId, setReferalId] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const auth = JSON.parse(localStorage.getItem("auth_user_info"));
  const applicantID = auth ? auth.id : null;

  useEffect(() => {

    // referal
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/util/view-referal`);
        setReferalData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    // All Info
    const fetchCommitmentData = async () => {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/applicant/get-application`, {
        headers: {
          "ApplicantId": applicantID,
          "Event": "commitment",
          "SessionKey": "123"
        }
      });
      const data = response?.data?.data;
      if (data) {
        setCommitmentData(data)
        setContent(data.content || "")
        setSkills(data.skills || "")
        setReferalId(data.referalId || 0)
      }

      setLoading(false)
    };
    fetchCommitmentData();
  }, []);


  // word coount
  const handleInputChange = (e) => {
    const wordCount = countWords(e.target.value);
    if (wordCount <= 800) {
      setContent(e.target.value);
      setError(null); // Clear the error
    } else {
      setError('Word count exceeds 800');
    }
  };

  const handlePaste = (e) => {
    // Get pasted data
    const pastedData = e.clipboardData.getData('text');

    // Calculate word count of current content and pasted data
    const wordCount = countWords(content + pastedData);

    // Check if word count exceeds limit
    if (wordCount > 800) {
      // console.error('Error: Word count exceeds 800');
      setError('Word count exceeds 800'); // Set the error
      e.preventDefault(); // Prevent the paste operation
    }
  };

  const countWords = (text) => {
    return text.trim().split(/\s+/).length;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!content) {
      NotificationManager.error("Cover Letter", "Field is required");
      return;
    }

    if (!skills) {
      NotificationManager.error("Skills", "Field is required");
      return;
    }

    if (!referalId || referalId === 0) {
      NotificationManager.error("Referal", "Field is required");
      return;
    }


    const postData = {
      applicantId: applicantID,
      coverLetter: content,
      engagement: "string",
      referalId: referalId ? Number(referalId) : null,
      skills: skills
    };
    // console.log(postData);

    await axios.post(`${process.env.REACT_APP_BASE_URL}/applicant/green-commitment`, postData, {
      headers: {
        "SessionKey": "123",
        "Content-Type": "application/json"
      }
    });
    navigate("/application/reference-letter");
  };

  return (
    <div>
      {/* Header Start */}
      <div className="mb-5" >

        <DashboardHeader />
      </div>

      <div className="container row justify-content-center m-auto mb-5">
        <div className="col-md-10">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-4">
              <Navbar />
            </div>
            <div className="col-sm-12 col-md-8 mb-4">
              {loading ? (
                <div className="d-flex justify-content-center">
                  <div className="spinner-bubble spinner-bubble-primary m-5"></div>
                </div>

              ) : (
                <form onSubmit={handleSubmit}>
                  <div className="card ">
                    <div className="card-body">
                      <h4 >
                        Personal Satement
                      </h4>
                      <p style={{ width: "100%" }}>
                        A personal statement is required from all applicants. Although there is no fixed format for your personal statement, below are some prompts to help you get started. Your statement must be written in English and should not exceed 800 words.
                      </p>
                      <ol >
                        <li >
                          What do you do or intend to do professionally, why does it matter, and what are your contributions and main achievements to date?
                        </li>
                        <li >
                          Why do you want to be a fellow, what do you hope to gain from your time at MSEC, and what will you bring to the fellowship?
                        </li>
                        <li>
                          How will you invest what you learn at MSEC back into your job, community, and society?
                        </li>
                        <li >
                          What is your vision for making the world a better place?
                        </li>
                        <li >
                          Where do you hope to be in five years’ time?
                        </li>
                      </ol>
                      <div className="form-group mb-4">
                        <label htmlFor="coverLetter">Personal Statement  <span className="text-danger">*</span></label>
                        <textarea
                          rows="20"
                          id="coverLetter"
                          placeholder="Type Statement"
                          className="form-control w-100"
                          value={content || ""}
                          onPaste={handlePaste}
                          onChange={handleInputChange}
                        ></textarea>
                        <div>
                          Word Count: {countWords(content)}
                          {error && <div className="text-danger">{error}</div>}
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="skills">List any relevant technical skills or knowledge related to
                          renewable energy (e.g., coursework, projects, certifications).<span className="text-danger">*</span></label>
                        <textarea
                          rows="10"
                          id="skills"
                          placeholder="List any relevant technical skills……"
                          className="form-control w-100"

                          value={skills || ''}
                          onChange={(e) => setSkills(e.target.value)}
                        ></textarea>
                      </div>

                      <div className="form-group mb-3 col-sm-12 col-md-12">
                        <label htmlFor={`socialMedia`}>How did you hear about this fellowship opportunity? <span className="text-danger">*</span></label>
                        <select
                          id={`socialMedia`}
                          className="form-control"
                          name="socialMedia"
                          value={referalId || ''}
                          onChange={(e) => setReferalId(e.target.value)}
                        >
                          {/* {referalId === '' ? <option key={0} value={0}>Select option</option> : null} */}
                          <option key={0} value={0}>Select option</option>

                          {referalData?.data && referalData?.data.map((item, idx) => (
                            <option key={idx} value={item.id}>
                              {item.network}
                            </option>
                          ))}
                        </select>
                      </div>


                    </div>
                    <div className="card-footer">
                      <div className="mc-footer">
                        <div className="row text-center">
                          <div className="col-lg-12 ">
                            <button type="submit" className="btn btn-primary text-white m-1">
                              Save & Next
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
      <NotificationContainer />
    </div>
  );
};

export default GreenEnergyCommitment;
